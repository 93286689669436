import Components from '../class/components'
import Dropdown from './Dropdown'
import GSAP from 'gsap'

export default class Navigation extends Components {
  constructor() {
    super({
      element: '.navigation',
      elements: {
        navigatotion: '.navigation',
        link: document.querySelectorAll('.nav__link'),
        menu: '.nav__menu',
        toggler: '.nav__toggle',
        header: '.header',
      },
    })
  }

  initNavigation() {
    this.dropdown = new Dropdown()
    this.dropdown.init()
    this.menuMobileHandler()
  }

  menuMobileHandler() {
    this.onShow()
    window.onscroll = (_) => this.scrollHeader()

    if (this.elements.toggler) {
      this.elements.toggler.onclick = (_) => {
        this.elements.toggler.classList.toggle('menu-active')
        this.elements.menu.classList.toggle('show-menu')
        this.onShow()
      }
    }
  }

  scrollHeader() {
    if (this.elements.header && window.scrollY >= 100)
      this.elements.header.classList.add('scroll-header')
    else
      this.elements.header !== null
        ? this.elements.header.classList.remove('scroll-header')
        : void 0
  }

  onShow() {
    return new Promise((resolve) => {
      GSAP.to(this.elements.header, {
        autoAlpha: 1,
      })
      GSAP.fromTo(
        this.elements.link,
        {
          duration: 0.6,
          y: '100%',
        },
        {
          y: 0,
          stagger: 0.1,
          onComplete: resolve,
        }
      )
    })
  }
}
