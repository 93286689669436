import scrollbehavior from "../utils/scrollbehavior";

export default class ScrollUp extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.render();
    window.addEventListener("scroll", this.showScrollButton);
    this.addEventListener("click", this.scrollToView);
  }

  disconnectedCallback() {}

  render() {
    this.setAttribute("href", "#top");
    this.id = "scroll-up";
    this.className = "scrollup";
    this.innerHTML = `
			<a href="#top">
				<i class="ri-arrow-up-s-line"></i>
			</a>
		`;
  }

  showScrollButton() {
    const scrollup = document.getElementById("scroll-up");
    if (this.scrollY >= 100 && scrollup) scrollup.classList.add("show-scroll");
    else scrollup.classList.remove("show-scroll");
  }

  scrollToView() {
		scrollbehavior();
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}

