//Home page block renderign by js
import datas from "./datas";
import PricingHandler from "./components/Price";
import ProjetsHandler from "./components/Projets";

//Page
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Service from "./pages/service";
import Page404 from "./pages/Page404";
import Realisation from "./pages/realisation";
import Mention from "./pages/mention";
import Privacy from "./pages/privacy";
import Faq from "./pages/faq";
import Nous from "./pages/nous";

//Components
import Preloader from "./components/Preloader";
import Navigation from "./components/Navigation";
import Cursor from "./components/Cursor";
import ScrollUp from "./components/Scrollup";
import Cookies from "./components/Cookies";
import Slider from "./utils/slider";
import Form from "./pages/forms";

//Utils
import { $ } from "./utils/shortselector";
import { getBaseUrl } from "./utils/functions";
import { LogoSlider } from "./components/Tools";

/**
 * TODO
 * Rétirer le HC avant de push pour la version en ligne
 */
window.assetsUrl = getBaseUrl() + "/assets/";
window.baseUrl = getBaseUrl();
class App {
	constructor() {
		this.getContent();
		this.initPages();

		this.setCursor("cursor");
		this.setScrollToUp();
		//this.createPreloader()
		this.showPages();
	}

	setNavigation() {
		this.navigation = new Navigation();
		this.navigation.initNavigation();
	}

	createPreloader() {
		if (this.pages.form.constructor !== this.page.constructor) {
			this.preloader = new Preloader();
			this.preloader.once("completed", (_) => this.onPreloaded());
		}
	}

	setCursor(element) {
		const customcursor = $(`.${element}`);
		if (
			!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
			customcursor !== null
		) {
			const cursor = new Cursor(customcursor);
			cursor.onhover(["a", "button"], { addClass: "-pointer" });
		}
	}

	setScrollToUp() {
		customElements.define("scroll-up", ScrollUp);
	}

	getContent() {
		this.content = $(".content");
		this.template = this.content.getAttribute("data-template");
	}

	initPages() {
		this.pages = {
			contact: new Contact(),
			page404: new Page404(),
			about: new About(),
			realisation: new Realisation(),
			service: new Service(),
			form: new Form(),
			mention: new Mention(),
			privacy: new Privacy(),
			faq: new Faq(),
			home: new Home(),
			nous: new Nous(),
		};

		this.page = this.pages[this.template];
		this.page.create();
		//ToolsHandler(datas.tools);

		window.addEventListener("DOMContentLoaded", () => {
			PricingHandler(datas.pricings);
			ProjetsHandler(datas.projets);
			LogoSlider(datas.logos, ".tools");
		});

		// if (
		//   this.page.constructor !== this.pages.form.constructor &&
		//   this.page.constructor !== this.pages.page404.constructor
		// ) {
		//   this.initCookies();
		// }
	}

	initCookies() {
		this.cookies = new Cookies($(".cookies"), {
			name: "cookieSet",
			expire: 365000,
		});
		this.declineCookies = $(".cookies__button-decline");
		this.validCookies = $(".cookies__button-valid");

		this.declineCookies.onclick = (_) => this.cookies.decline(true);
		this.validCookies.onclick = (_) => this.cookies.accept();
	}

	onPreloaded() {
		this.preloader.destroy();
		console.log("preload end");
		this.setNavigation();
		this.page.show();

		if (
			this.page.constructor !== this.pages.form.constructor &&
			this.page.constructor !== this.pages.page404.constructor
		) {
			this.initCookies();
		}
	}

	showPages() {
		this.setNavigation();
		new Slider();

		this.page.show();

		if (
			this.page.constructor !== this.pages.form.constructor &&
			this.page.constructor !== this.pages.page404.constructor
		) {
			this.initCookies();
		}
	}
}

new App();
