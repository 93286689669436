import split from '../../utils/splittext'
import Pages from '../../class/Pages'
import TeamSlider from '../../utils/teamSlider'

export default class Nous extends Pages {
	constructor() {
		super({
			id: 'nous',
			element: '.nous',
			elements: {
				teamName: '.nous__slider__content__role__name',
				teamRole: '.nous__slider__content__role__post',
				teamDesc: '.nous__slider__content__role__description',
				teamSocialIcon: '.nous__slider__content__social',
			},
		})
	}

	create() {
		super.create()
		console.log(this.elements)
		const teamSlider = new TeamSlider(this.onclick)
	}

	onclick(currentContent) {}
}
