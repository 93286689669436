import Animation from '../class/Animation'
import GSAP from 'gsap'
import each from 'lodash/each'
import split from '../utils/splittext'

export default class Title extends Animation {
  constructor({ element, elements }) {
    super({ element, elements })

    split(this.element, '<br>')
    split(this.element, '<br>')

    this.spans = this.element.querySelectorAll('span span')

    super.createObserver()
    //this.animateIn();
  }

  animateIn() {
    GSAP.to(this.element, {
      duration: 0.4,
      opacity: 1,
    })

    GSAP.to(this.spans, {
      delay: 0,
      duration: 1.2,
      stagger: 0.2,
      ease: 'expo.inOut',
      y: '0%',
      opacity: 1,
    })
  }

  animateOut() {
    GSAP.to(this.element, {
      opacity: 0,
    })

    GSAP.set(this.spans, {
      delay: 0,
      duration: 1.2,
      opacity: 0,
      ease: 'expo.inOut',
      stagger: 0.1,
      y: '100%',
    })
  }
}
