import each from 'lodash/each'
import map from 'lodash/map'
import GSAP from 'gsap'
import Title from '../animation/Title'
import Images from '../animation/Images'

export default class Pages {
	constructor({ id, element, elements }) {
		this.id = id
		this.selector = element
		this.selectorChildrens = {
			...elements,
			animationTitles: '[data-animation="title"]',
			animationImages: '[data-animation="images"]',
		}
	}

	create() {
		this.element = document.querySelector(this.selector)
		this.elements = {}

		each(this.selectorChildrens, (entry, key) => {
			if (
				entry instanceof HTMLElement ||
				entry instanceof window.NodeList ||
				Array.isArray(entry)
			) {
				this.elements[key] = entry
			} else {
				this.elements[key] = [...document.querySelectorAll(entry)]
				if (this.elements[key].length === 0) {
					this.elements[key] = null
				} else if (this.elements[key].length === 1) {
					this.elements[key] = document.querySelector(entry)
				}
			}
		})
	}

	createAnimation() {
		each(this.elements.animationTitles, (element) => {
			new Title({ element })
		})

		each(this.elements.animationImages, (element) => {
			new Images({ element })
		})
	}

	show() {
		this.createAnimation()
		return new Promise((resolve) => {
			GSAP.to(this.selector, {
				autoAlpha: 1,
				delay: 0,
				duration: 0.1,
				onComplete: resolve,
			})
		})
	}
}
