import { $, $All } from '../../utils/shortselector'
import { validName, validEmail, validFileType, isNotEmpty, getBaseUrl } from '../../utils/functions'

export default function formHomeHnadler() {
  let form = $('.spec__form')
  if (form) {
    //File handle variables
    let droptarget = form.querySelector('.dragndrop-zone')
    let inputfile = form.querySelector('#dragndrop-btn')
    let filerror = form.querySelector('.dragndrop-zone p')
    let filename = form.querySelector('.dragndrop-zone h6')
    let submitbtn = form.querySelector('.submit__spec-form')
    let dropfile
    let isInValidFile

    let uploadOps = {
      filename: filename,
      filerror: filerror,
      target: droptarget,
    }

    //Form submit validation variables
    let inputarr = Array.from($All('[id*=home]'))

    //Desactive le bouton envoyer
    //submitbtn.disabled = true;

    //EVENT
    //Manipule les fichier par selection
    inputfile.addEventListener('change', handleFile)

    //Manipule les fichier deposer en drag en drop
    droptarget.addEventListener('dragover', dragOver)
    droptarget.addEventListener('dragenter', dragEnter)
    droptarget.addEventListener('dragleave', dragLeave)
    droptarget.addEventListener('drop', onDrop)

    //Soumission du formulaire
    form.addEventListener('submit', submitForm)

    //Files Functions
    function handleFile() {
      for (let i = 0; i < this.files.length; i++) {
        const element = this.files[i]

        if (validFileType(element)) {
          dropfile = element
          if (droptarget.classList.contains('-alert-error'))
            droptarget.classList.remove('-alert-error')
          droptarget.classList.add('-active-zone')
          filename.textContent = element.name
          filerror.textContent = 'Fichier conforme'
        } else {
          if (droptarget.classList.contains('-active-zone'))
            droptarget.classList.remove('-active-zone')
          droptarget.classList.add('-alert-error')
          filerror.textContent =
            'Fichier non conforme : le fichier doit être au format pdf, word ou exel,la taille du fichier doit être inférieur à 5 Mo'
        }
      }
    }

    //Drag and Drop Functions
    function dragOver(e) {
      e.preventDefault()
      e.dataTransfer.dropEffect = 'move'
    }

    function dragEnter(e) {
      e.preventDefault()
      if (!e.target.classList.contains('-active-zone')) {
        e.target.classList.add('-active-zone')
      }
    }

    function dragLeave(e) {
      e.preventDefault()
      if (e.target.classList.contains('-active-zone')) {
        e.target.classList.remove('-active-zone')
      }
    }

    function onDrop(e) {
      e.preventDefault()
      // Possibilité d'utilisé dataTransfer.items ou dataTransfert.files (plus facile à utlisé)
      let data = e.dataTransfer.items
      for (let f = 0; f < data.length; f++) {
        const elem = data[f]

        if (elem.kind === 'file') {
          let file = elem.getAsFile()
          if (validFileType(file)) {
            dropfile = file
            filename.textContent = file.name
            filerror.textContent = 'Fichier conforme'
          } else {
            //le fichier est d'un autre type (non valide)
            isInValidFile = true
            filerror.textContent =
              'Fichier non conforme : le fichier doit être au format pdf, word et exel,la taille du fichier doit être inférieur à 5 Mo'
          }
        }
      }
    }

    homeFormValidation(form, inputarr)

    //Submit form handler
    function submitForm(e) {
      e.preventDefault()

      let formData = new FormData(form)
      formData.delete('cdc-file')

      let validNumber = 0

      for (let [name, value] of formData) {
        if (value !== '') {
          validNumber++
        }
      }

      if (validNumber === 6 && dropfile) {
        formData.set('cdc-file', dropfile)
        //for(let i of formData) console.log(i)

        //Post data
        postFormData(
          '/Send_cdc',
          { method: 'POST', cors: 'cors', body: formData },
          uploadOps,
          submitbtn
        )
      } else {
        //Manage error
        console.log(validNumber, dropfile)
      }
    }
  }

  function postFormData(endpoint, options, overwriteData, submitbtn) {
    console.log(submitbtn)
    submitbtn.innerHTML = 'Veuiller patienter . . .'
    let baseUrl = getBaseUrl()
    let url = `${baseUrl + endpoint}`
    fetch(url, options)
      .then((res) => {
        res.text().then((data) => {
          //Reset la couleur du drag and drop
          overwriteData.target.classList.contains('-active-zone')
            ? overwriteData.target.classList.remove('-active-zone')
            : void 0
          //Reset les textes du drag and drop
          overwriteData.filename.textContent = 'Selectionner un fichier ou glisser deposer'
          overwriteData.filerror.textContent = 'Le fichier doit être au format Pdf, Word, Excel'
          //Reset les messages d'erreurs
          Array.from(document.querySelectorAll('.form__alert-message')).forEach(
            (el) => (el.style.display = 'none')
          )
          //Reset le contenu du formulaire
          form.reset()

          //Afficher la popup de prise en compte du projet
          modalBehavior('modal', 'modal__container', 'close-modal')

          //Reset le text du bouton
          submitbtn.innerHTML = 'Soumettre ma demande'
        })
      })
      .catch((err) => {
        console.log("Une erreur c'est produites : " + err)
      })
  }

  function homeFormValidation(form, inputArr) {
    inputArr.forEach((element) => {
      element.addEventListener('input', () => {
        let formAlertMessage = element.parentNode.querySelector('.form__alert-message')
        if (element.getAttribute('name') === 'nom') {
          validName(element, formAlertMessage)
        } else if (element.getAttribute('name') === 'email') {
          validEmail(element, formAlertMessage)
        } else if (element.getAttribute('name') === 'Message') {
          isNotEmpty(element, formAlertMessage)
        }
      })
    })
  }

  /*==================== Modal handler ====================*/
  const modalBehavior = (modalId, container, closeId) => {
    const modal = document.querySelector(`#${modalId}`)
    const modalContainer = document.querySelector(`.${container}`)
    const close = document.querySelector(`.${closeId}`)

    if (close && modal && container) {
      showModal()
    }

    if (close) {
      close.addEventListener('click', closeModal)
    }

    function showModal() {
      if (
        !modal.classList.contains('modal-open') &&
        !modalContainer.classList.contains('modal-open-container')
      ) {
        modal.classList.add('modal-open')
        modalContainer.classList.add('open-modal-container')
      }
    }

    function closeModal(e) {
      modalContainer.classList.remove('open-modal-container')
      modal.classList.remove('modal-open')
      if (!modal.classList.contains('fade-out') && !modal.classList.contains('fade-out')) {
        modalContainer.classList.add('fade-out')
        modal.classList.add('fade-out')
      } else {
        modalContainer.classList.remove('fade-out')
        modal.classList.remove('fade-out')
      }
    }
  }
}
