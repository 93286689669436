/**
 * Ajoute un tooltips à un element du DOM
 */
export default class Tooltips {
	/**
	 * @param {HTMLElement} element - L'élement sur lequel le tooltips doit apparaître
	 * @param {string} text - Le texte contenue dans le tooltips
	 */
	constructor(element) {
		this.element = element;
		this.tooltipsText = element.dataset.tooltiptitle;
		this.eventHandler();
	}

	/**
	 * Créer l'élément HTML du tooltips
	 * @param {HTMLElement} t - Le texte à insérer
	 */
	 createTooltipBox(t) {
		let div = document.createElement("DIV");
		div.setAttribute("class", "tooltips");
		let text = document.createElement("SPAN");
		text.setAttribute("class", "tooltips-text");
		text.innerText = t;
		div.appendChild(text);
		return div;
	}

	/**
	 * Positione le tooltips en fonction des évènements
	 */

	  eventHandler() {
		this.element.addEventListener("mouseover", this.mouseOver);
		this.element.addEventListener("mouseout", this.mouseOut);
	 }

	  mouseOver = (e) => {
		this.tooltips = this.createTooltipBox(this.tooltipsText);
		document.body.append(this.tooltips);

		let target = this.element;
		let options = {
			margin: 16,
			bottom: window.innerHeight - target.getBoundingClientRect().bottom,

			targetops: {
				height: target.offsetHeight,
				width: target.offsetWidth,
				left: target.getBoundingClientRect().x,
				top: target.getBoundingClientRect().y,
			},

			tooltipsops: {
				height: this.tooltips.offsetHeight,
				width: this.tooltips.offsetWidth,
			},
		};

		this.setPosition(options);
	 };

	  mouseOut = (e) => {
		this.tooltips.classList.contains("tooltips-visible") ? this.tooltips.classList.remove("tooltips-visible") : void 0;
		document.body.removeChild(this.tooltips);
	};

	/**
	 * Modifie la position du tooltips pour un rendu éfficace
	 * @param {Object} options - Les options de positionnement
	 */
	 setPosition(options) {
		this.tooltips.style.top = `${options.targetops.top - options.margin - options.tooltipsops.height}px`;
		this.tooltips.style.left = `${options.targetops.left - (options.tooltipsops.width - options.targetops.width) / 2}px`;
		this.tooltips.classList.add("tooltips-visible");
	}
}
