import { createElement, appendChilds } from "../utils/functions/dom";

export function ToolsHandler(datas) {
	const DATAS = datas;
	const container = document.querySelector(".hero__tools__body");
	if (!container || !DATAS) {
		console.error("Impossible de cibler le container ou les donnée");
		return;
	}

	container.appendChild(Table(DATAS));
}

function Table(datas) {
	const table = createElement("table", {
		className: "table",
	});

	const caption = createElement("caption", {
		textContent: datas.caption,
	});

	const head = TableHead(datas.head);
	const body = TableBody(datas.rows);

	appendChilds(table, [caption, head, body]);

	return table;
}

function TableHead(rows) {
	const head = createElement("thead", {});
	rows.forEach((row) => {
		appendChilds(head, [TableRow(row, "th")]);
	});

	return head;
}

function TableBody(rows) {
	const body = createElement("tbody", {});

	rows.forEach((row) => {
		appendChilds(body, [TableRow(row, "td")]);
	});

	return body;
}

function TableRow(cells, type) {
	const row = createElement("tr", {});

	if (type === "th") {
		cells.forEach((cell) => appendChilds(row, [createTableHeadCell(cell)]));
	} else {
		for (let cell in cells) {
			if (cell == "logo") {
				const logo = `<div class="tools__logo"><img src="${window.assetsUrl}${cells[cell]}"/></div>`;
				appendChilds(row, [createTableCell(logo)]);
			} else {
				appendChilds(row, [createTableCell(cells[cell])]);
			}
		}
	}

	return row;
}

function createTableHeadCell(content) {
	const th = createElement("th", {});
	const child = createElement("div", {
		className: "table__body__content",
		innerHTML: content,
	});

	appendChilds(th, [child]);
	return th;
}

function createTableCell(content) {
	const td = createElement("td", {});
	const child = createElement("div", {
		className: "table__body__content",
		innerHTML: content,
	});
	appendChilds(td, [child]);

	return td;
}

export function LogoSlider(datas, containerClass) {
	const container = document.querySelector(containerClass);
	if (!container) {
		console.error("Impossible de cibler le container");
		return;
	}

	const slideContainer = createElement("div", { className: "tools-slide-container" });
	const slideWrapper = createElement("div", { className: "tools-slide-wrapper" });

	//Construit l'arborescence du DOM
	datas.forEach((item, index) => {
		const node = SlideItem({ index, item });
		slideContainer.appendChild(node);
	});

	slideWrapper.appendChild(slideContainer);

	container.appendChild(slideWrapper);

	Slider(container.querySelector(".tools-slide-wrapper"), {
		startIndex: 1,
		gap: 24,
		slideVisible: 3,
		duration: 2000,
		velocity: 0.2,
	});
}

function Slider(wrapper, { startIndex, gap, slideVisible, duration }) {
	const options = Object.assign(
		{
			startIndex: 3,
			gap: 24,
			slideVisible: 3,
			duration: 2000,
			velocity: 0.1,
		},
		{ startIndex, gap, slideVisible, duration }
	);
	let container = wrapper.querySelector(".tools-slide-container");
	let childs = [...container.childNodes];
	let slideItemSize = wrapper.getBoundingClientRect().width / options.slideVisible;
	let currentIndex = startIndex;
	let timeOuFunctionId = null;

	//Définit la largeur calculé de chaque enfant
	childs.forEach((child) => {
		child.style.paddingRight = options.gap + "px";
		child.style.paddingLeft = options.gap + "px";
		child.style.width = slideItemSize + "px";
		child.style.height = slideItemSize + "px";
	});

	setActiveSlideIndex(options.startIndex);

	let translateInterval = setInterval(translateHandler, options.duration);

	onResize();

	function animate() {
		stopAnimation();
		startAnimation();
	}

	function startAnimation() {
		translateInterval = setInterval(translateHandler, options.duration);
	}

	function stopAnimation() {
		clearInterval(translateInterval);
	}

	function computeChildSize() {
		slideItemSize = wrapper.getBoundingClientRect().width / options.slideVisible;

		//Définit la largeur calculé de chaque enfant
		childs.forEach((child) => {
			child.style.width = slideItemSize + "px";
			child.style.height = slideItemSize + "px";
		});
	}

	function translateHandler() {
		childs = [...container.childNodes];
		const cloneItem = childs[currentIndex].cloneNode(true);
		cloneItem.classList.remove("activeItem");
		container.appendChild(cloneItem);

		translateX(container, slideItemSize, currentIndex);
		childs[currentIndex].classList.remove("activeItem");

		currentIndex++;

		childs[currentIndex].classList.add("activeItem");
	}

	function onResize() {
		window.addEventListener("resize", () => {
			stopAnimation();
			computeChildSize();

			onResizeEnd(() => startAnimation(), timeOuFunctionId);
		});
	}

	function onResizeEnd(calback, timeOuId) {
		clearTimeout(timeOuId);
		timeOuId = setTimeout(() => {
			calback();
		}, 500);
	}

	function setActiveSlideIndex(activeIndex) {
		childs[activeIndex].classList.add("activeItem");
	}

	function translateX(element, size, index) {
		const translate = -size * index;
		element.style.transform = `translate3d(${translate}px, 0, 0)`;
	}
}

function SlideItem({ index, item }) {
	const node = createElement("div", {
		className: "slider-item",
	});

	const logo = `<img class="tools-logo" src="${window.assetsUrl}${item.src}" alt="${item.alt}"/>`;

	node.setAttribute("slideIndex", index);

	const child = createElement("div", {
		className: "slide-child",
		innerHTML: logo,
	});

	appendChilds(node, [child]);

	return node;
}
