import Pages from "../../class/Pages";

export default class Service extends Pages {
  constructor() {
    super({
      id: "service",
      element: ".service",
      elements: {},
    });
  }
}
