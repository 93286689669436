import Components from "../class/components";
import each from "lodash/each";
//import GSAP from "gsap";

export default class Dropdown extends Components {
  constructor() {
    super({
      element: ".dropdown",
      elements: {
        menu: ".dropdown-menu",
        toggler: ".dropdown-toggle",
      },
    });

  }

  init() {
    this.toggleDropdown();
    this.setDropdownPosition();
  }

  toggleDropdown() {
    each(this.elements.toggler, (el) => {
      let parent = el.parentNode;
      let elementtoshow = parent.querySelector(".dropdown-menu");
      el.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
        elementtoshow?.classList.toggle("dropdown-show");
      });
      // el.onblur = () => {
      // 	elementtoshow?.classList.remove("dropdown-show");
      // };
    });

    this.setDropdownPosition()
  }

  setDropdownPosition() {
    each(this.elements.toggler, (el) =>
      el.addEventListener("click", this.handleposition)
    );
  }

  handleposition(e) {
    e.preventDefault();
    e.stopPropagation();
    let parent = this.parentNode;
    let dropmenu = parent.querySelector(".dropdown-menu");
    //Calculer la distance entre le bas du boutton et le bottom de la page (l'écran | viewport)
    //Stoker la hauteur du dropdown, du boutton et la marge souhaiter  entre le boutton et le dropdown menu
    let options = {
      margin: 20,
      height: this.offsetHeight,
      bottom: window.innerHeight - this.getBoundingClientRect().bottom,
      dropdownHeight: dropmenu.offsetHeight,
    };

    //Verifier si cette distante est inférieur à la taille (la hauteur) du dropdown-menu
    if (options.bottom < options.dropdownHeight) {
      //Si c'est le cas modifier le top du dropdown-menu avec la somme des trois variables
      dropmenu.style.top = `-${
        options.dropdownHeight + options.height + options.margin
      }px`;
    } else {
      dropmenu.style.top = "0px";
    }
  }
}
