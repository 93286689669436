import GSAP from 'gsap'
import Animation from '../class/Animation'

export default class Images extends Animation {
  constructor({ element, elements }) {
    super({ element, elements })
    super.createObserver()
  }

  animateIn() {
    GSAP.fromTo(
      this.element,
      {
        autoAlpha: 0,
        rotate: -18,
        x: '-16px',
        ease: 'expo.inOut',
        y: '6px',
      },
      {
        autoAlpha: 1,
        y: 0,
        x: 0,
        rotate: 0,
        stagger: 0.1,
        duration: 0.8,
      }
    )
  }

  animateOut() {
    GSAP.fromTo(
      this.element,
      {
        autoAlpha: 1,
        y: '0',
      },
      {
        autoAlpha: 0,
        y: '200px',
      }
    )
  }
}
