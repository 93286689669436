import { getCookie } from "../utils/functions";
import GSAP from "gsap";

export default class Cookies {
  #COOKIE__SET = false;

  constructor(cookie, ops) {
    this.cookie = cookie;
    this.options = Object.assign(
      {
        name: "cookieSet",
        expire: 350000,
      },
      ops
    );

    this.initcookies();
  }

  initcookies() {
    let cookiesValue = getCookie(`${this.options.name}`);
    if (typeof cookiesValue === "undefined" || cookiesValue === "false") {
      this.show();
    }
  }

  decline(bool) {
    this.hide();
    if (bool) document.cookie = `${this.options.name}=${this.#COOKIE__SET}`;
  }

  accept() {
    this.#COOKIE__SET = true;
    document.cookie = `${this.options.name}=${this.#COOKIE__SET}; max-age=${
      this.options.expire
    }`;
    this.decline(false);
  }

  destroy() {
    this.cookie.parentNode.removeChild(this.cookie);
  }

  show() {
    GSAP.to(this.cookie, {
      opacity: 1,
      visibility: "visible",
      bottom: 32,
      duration: 1,
      delay: 15,
    });
  }

  hide() {
    GSAP.to(this.cookie, {
      opacity: 0,
      visibility: "hidden",
      bottom: 0,
      duration: 1,
      //onComplete : this.destroy()
    }, '-=1');
  }
}
