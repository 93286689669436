import { lerp } from '../utils/functions'

export default class Cursor {
  constructor(cursor) {
    this.cursor = cursor
    this.cursorPos = {
      currentx: 0,
      currenty: 0,
      targetx: 0,
      targety: 0,
    }

    this.centerX = this.cursor.clientWidth / 2
    this.centerY = this.cursor.clientHeight / 2

    this.init()
  }

  init() {
    this.addEventListener()
    this.update()
  }

  onMouseEnter() {
    this.cursor.classList.add('-visible')
  }

  onMouseLeave() {
    this.cursor.classList.remove('-visible')
  }

  onMouseMove(event) {
    this.cursorPos.targetx = event.clientX
    this.cursorPos.targety = event.clientY
  }

  onhover(elements, ops) {
    const options = Object.assign({}, ops)

    elements.forEach((selector) => {
      const node = Array.from(document.querySelectorAll(`${selector}`))
      node.forEach((el) => {
        this.hoverBehavior(el, options)
      })
    })
  }

  hoverBehavior(el, opts) {
    const { addClass } = opts
    el.addEventListener('mouseenter', () => {
      addClass ? this.cursor.classList.add(`${addClass}`) : void 0
    })

    el.addEventListener('mouseleave', () => {
      addClass ? this.cursor.classList.remove(`${addClass}`) : void 0
    })
  }

  addEventListener() {
    window.addEventListener('mouseenter', this.onMouseEnter.bind(this))
    window.addEventListener('mouseleave', this.onMouseLeave.bind(this))
    window.addEventListener('mousemove', this.onMouseMove.bind(this))
  }

  update() {
    this.cursorPos.currentx = lerp(this.cursorPos.currentx, this.cursorPos.targetx, 0.1)
    this.cursorPos.currenty = lerp(this.cursorPos.currenty, this.cursorPos.targety, 0.1)

    this.cursor.style.transform = `translate(${this.cursorPos.currentx - this.centerX}px, ${
      this.cursorPos.currenty - this.centerY
    }px)`

    window.requestAnimationFrame(this.update.bind(this))
  }
}
