import Components from "./components";

export default class Animation extends Components {
  constructor({ element, elements }) {
    super({
      element,
      elements,
    });

    //this.createObserver();
  }

  createObserver() {
    // this.observerOptions = {
    //   root: null,
    //   rootMargin : "0px",
    //   threshold : .4
    // };
    this.observer = new window.IntersectionObserver((entries) => {
      entries.forEach((element) => {
        if (element.isIntersecting) {
          this.animateIn();
        } else {
          this.animateOut();
        }
      });
    });

    this.observer.observe(this.element);
  }

  animateIn() {}

  animateOut() {}
}
