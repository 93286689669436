import { createElement, appendChilds } from "../utils/functions/dom";

export default function ProjetsHandler(datas) {
	const container = document.querySelector(".projects__content");
	if (!container) {
		console.error("Impossible de cibler le container");
		return;
	}
	container.appendChild(ProjectCards(datas));
}

function ProjectCards(projects) {
	const cards = createElement("div", {
		className: "project__cards",
	});

	projects.forEach((project) => {
		cards.appendChild(ProjectCard(project));
	});

	return cards;
}

function ProjectCard(project) {
	const element = createElement("a", {
		className: "project__card",
		href: project.link,
	});

	const videoContainer = createElement("div", {
		className: "project__card__presentation",
	});

	const video = createElement("video", {
		className: "project__card__video",
		src: window.assetsUrl + project.video.src,
		loop: true,
		autoplay: true,
		alt: project.video.alt,
	});

	video.setAttribute("playinline", "");

	const body = createElement("div", {
		className: "project__body",
	});

	const content = createElement("div", {
		className: "project__content",
	});

	const title = createElement("h3", {
		className: "project__card__title title",
		textContent: project.title,
	});

	const description = createElement("p", {
		className: "project__card__description",
		textContent: project.description,
	});

	const tags = ProjectTags(project.tags);

	appendChilds(videoContainer, [video]);
	appendChilds(content, [title, description]);
	appendChilds(body, [content, tags]);
	appendChilds(element, [videoContainer, body]);

	return element;
}

function ProjectCover(cover) {
	const element = createElement("picture", {
		className: "project__card__cover",
	});

	for (let size in cover.size) {
		switch (size) {
			case "tablet":
				element.appendChild(
					createElement("source", {
						srcset: `${window.assetsUrl}images/projets/${cover.size[size]}`,
						media: "(min-width: 640px)",
					})
				);
				break;
			case "desktop":
				element.appendChild(
					createElement("source", {
						srcset: `${window.assetsUrl}images/projets/${cover.size[size]}`,
						media: "(min-width: 1080px)",
					})
				);
				break;
			default:
				element.appendChild(
					createElement("img", {
						src: `${window.assetsUrl}images/projets/${cover.size[size]}`,
						alt: cover.alt,
					})
				);
		}
	}

	return element;
}

function ProjectTags(tags) {
	const element = createElement("div", {
		className: "project__tags",
	});

	if (!tags || tags.length === 0) return;
	tags.forEach((label) => {
		element.appendChild(tag(label));
	});

	return element;
}

function tag(label) {
	return createElement("span", {
		className: "project__tag",
		textContent: label,
	});
}
