import Pages from "../../class/Pages";

export default class Privacy extends Pages {
  constructor() {
    super({
      id: "privacy",
      element: ".privacy",
      elements: {},
    });
  }

  create() {
    super.create();
  }
}
