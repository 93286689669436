import Pages from "../../class/Pages";

export default class Mention extends Pages {
  constructor() {
    super({
      id: "Mention",
      element: ".mention",
      elements: {},
    });
  }

  create() {
    super.create();
  }
}
