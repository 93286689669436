export default function PricingHandler(datas) {
	const PRICINGS = datas;
	const BUTTONLABEL = "Je me lance";

	const container = document.querySelector(".pricing__cards");
	if (!container) return;
	container.innerHTML = "";

	PRICINGS.forEach((pricing) => container.appendChild(PricingCard(pricing)));

	function PricingCard({ service, description, features, link }) {
		const card = Object.assign(document.createElement("li"), {
			className: "pricing__card",
		});

		const header = PricingCardHeader(service, description);
		const body = PricingCardBody(features);
		const footer = PricingCardFooter(link);

		card.appendChild(header);
		card.appendChild(body);
		card.appendChild(footer);
		return card;
	}

	function PricingCardHeader(serviceTitle, description) {
		const element = Object.assign(document.createElement("div"), {
			className: "pricing__card__header",
		});

		const content = Object.assign(document.createElement("div"), {
			className: "pricing__card__header__content",
		});

		const title = Object.assign(document.createElement("h3"), {
			className: "title",
			textContent: serviceTitle,
		});

		const desc = Object.assign(document.createElement("p"), {
			textContent: description,
		});

		const icon = Object.assign(document.createElement("div"), {
			className: "pricing__card__header__icon",
			innerHTML: "<i class='ri-braces-fill'></i>",
		});

		content.appendChild(title);
		content.appendChild(desc);
		element.appendChild(content);
		element.appendChild(icon);

		return element;
	}
	function PricingCardBody(pricingFeatures) {
		const element = Object.assign(document.createElement("div"), {
			className: "pricing__card__body",
		});

		const title = Object.assign(document.createElement("h6"), {
			textContent: "Contenu de la prestation",
		});

		const list = PricingFeatures();
		pricingFeatures.forEach((feature) => {
			list.appendChild(PricingFeaturesItem(feature));
		});

		element.appendChild(title);
		element.appendChild(list);

		return element;
	}

	function PricingCardFooter(href) {
		const element = Object.assign(document.createElement("div"), {
			className: "pricing__card__footer",
		});

		const link = Object.assign(document.createElement("a"), {
			className: "button button-secondary",
			textContent: BUTTONLABEL,
			href: href,
		});

		element.appendChild(link);

		return element;
	}
	function PricingFeatures() {
		const list = Object.assign(document.createElement("ul"), {
			className: "pricing__features",
		});

		return list;
	}

	function PricingFeaturesItem(feature) {
		const list = Object.assign(document.createElement("li"), {
			className: "pricing__features__item",
		});

		const icon = Object.assign(document.createElement("i"), {
			className: "ri-checkbox-circle-fill",
		});

		const content = Object.assign(document.createElement("p"), {
			textContent: feature,
		});

		list.appendChild(icon);
		list.appendChild(content);

		return list;
	}
}
