import Pages from "../../class/Pages";

export default class Realisation extends Pages {
  constructor() {
    super({
      id: "realisations",
      element: ".realisations",
      elements: {},
    });
  }

}
