/**
 * Fetch form
 */
import { $, $All } from '../../utils/shortselector'
import bubbleText from '../../utils/bubbletext'
import { getBaseUrl } from '../../utils/functions'

export function formFetcher() {
  let nextBtn = $('.form__btn-nav-right')
  let prevBtn = $('.form__btn-nav-left')
  let fetchWrapper = $('.form__fetch-container')
  let formType = fetchWrapper?.dataset.formtype
  let services = []

  //Steper handler component
  let steperliner = $('.steper__liner')
  let steplinelist = $('.steper__list')
  let steperfillwidth = $('.steper__fill-liner')

  //Cache la section du choix de service
  let slidehome = $('.slidehome')

  slidehome?.classList.add('slide-visible')
  /**
   * Récupérer les données avec fetch et les masquer - display none
   *
   */

  //lors du click sur le boutton suivant
  //nextBtn?.addEventListener("click", nextslide);
  //lors du click sur le boutton Précédent
  //prevBtn?.addEventListener("click", prevslide);

  if (formType === 'design') {
    //Récupère les input de choix de service
    services = Array.from($All("input[name='typedeservice']"))

    //Lance une fonction pour chaque type d'input coché
    services.forEach((service) => {
      service.addEventListener('input', () => {
        if (service.checked) {
          if (service.value == 'logo') {
            fetchFormContent('design/logo')
          } else if (service.value == 'flyers') {
            fetchFormContent('design/flyers')
          } else if (service.value == 'cv') {
            fetchFormContent('design/cv')
          }
        }
      })
    })
  } else {
    if (fetchWrapper) fetchFormContent('devform')
  }

  function fetchFormContent(path) {
    //Fetch option
    const siteUrl = `${getBaseUrl()}/Formulaires`
    const url = `${siteUrl}/${path}`
    console.log(siteUrl, url)
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      },
      mode: 'no-cors',
    }

    fetch(url, options)
      .then((res) => res.text())
      .then((data) => {
        console.log(data)
        fetchWrapper.innerHTML = data

        let currentSlide = 0
        let currentStep = 0
        let formSection = Array.from($All('.form__section'))

        let wrapperchildren = Array.from(fetchWrapper.children)
        setDataAttribute(wrapperchildren, 'd-none', 'data-slideindex')
        let steplinerwidth = steperliner.getBoundingClientRect().width / wrapperchildren.length

        //Step liner handle
        //Créer le nombre d'étape
        let steplinelistfisrtchild = $('.steper__list-item')
        if (steplinelistfisrtchild === null) {
          createStepList()
        } else {
          $All('.steper__list-item').forEach((l) => steplinelist.removeChild(l))
          createStepList()
        }

        let steplinelistchild = Array.from($All('.steper__list-item'))
        steplinelistchild[0].classList.add('-activ-step')

        bubbleText('bubbleText')

        //steperfillwidth.style.width = "0";

        //Aller au slide suivant
        nextBtn.addEventListener('click', nextSlide)

        //Retour au slide précédent
        prevBtn.addEventListener('click', prevSlide)

        //Gestion des slides
        function nextSlide() {
          if (currentSlide >= wrapperchildren.length) {
            return
          }

          //step liner

          steperfillwidth.style.width = `${steplinerwidth * (currentStep + 1)}px`

          steplinelistchild[currentStep].classList.add('-finish-step')
          steplinelistchild[currentStep].classList.remove('-activ-step')
          steplinelistchild[currentStep].innerHTML = "<i class='ri-check-double-fill'></i>"

          //Envois du formulaire uniquement si l'utilisation des données est accepté
          let valideTermCheckbox = document.getElementById('term')
          valideTermCheckbox.addEventListener('input', () => {
            if (valideTermCheckbox.checked) {
              nextBtn.setAttribute('type', 'submit')
            } else {
              nextBtn.setAttribute('type', 'button')
            }
          })

          //je recupère le slide visible et je le cache avec la classe d-none
          formSection.forEach((slide) => {
            if (parseFloat(slide.dataset.slideindex) === currentSlide) {
              slide.classList.remove('slide-visible')
              setTimeout(() => {
                slide.classList.add('d-none')
              }, 600)
            }
          })

          currentStep++
          steperfillwidth.addEventListener('transitionend', () => {
            steplinelistchild[currentStep].classList.add('-activ-step')
          })

          currentSlide++

          if (prevBtn.classList.contains('button-disabled'))
            prevBtn.classList.remove('button-disabled')
          // je selectionne le slide suivant je retire la classe d-none
          formSection.forEach((slide) => {
            if (parseFloat(slide.dataset.slideindex) === currentSlide) {
              slide.classList.remove('d-none')
              setTimeout(() => {
                slide.classList.add('slide-visible')
              }, 600)
            }
          })

          //Affiche le texte Soumettre au button si on se trouve au dernnier slide
          if (currentSlide === wrapperchildren.length) {
            nextBtn.style.fontSize = 'unset'
            nextBtn.style.width = 'max-content'
            nextBtn.innerHTML = 'Soumettre <i class="ri-send-plane-2-line"></i>'
          }
        }

        function prevSlide(e) {
          e.preventDefault()

          //Si on à atteind le premier slide on ne fait rien
          if (currentSlide === 0) {
            this.classList.add('button-disabled')
            return
          }

          //step liner
          //steplinelistchild[currentSlide].classList.remove("-finish-step");
          steplinelistchild[currentStep].classList.remove('-activ-step')
          steplinelistchild[currentStep].innerHTML = currentStep + 1

          currentStep--
          steperfillwidth.style.width = `${steplinerwidth * currentStep}px`
          //Retour à l'état précedent
          steplinelistchild[currentStep].classList.remove('-finish-step')
          steplinelistchild[currentStep].classList.add('-activ-step')

          //Reset le texte du bouton suivant
          if (currentSlide <= wrapperchildren.length) {
            nextBtn.setAttribute('type', 'button')
            nextBtn.style.fontSize = 'var(--h4-font-size)'
            nextBtn.style.width = '48px'
            nextBtn.innerHTML = '<i class="ri-arrow-right-s-line"></i>'
          }

          //je recupère le slide visible et je le cache avec la classe d-none
          formSection.forEach((slide) => {
            if (parseFloat(slide.dataset.slideindex) === currentSlide) {
              slide.classList.remove('slide-visible')
              setTimeout(() => {
                slide.classList.add('d-none')
              }, 0)
            }
          })

          currentSlide--

          //Rendre impossible de cliquer si nous somme au slide 0
          if (currentSlide < 1) {
            prevBtn.classList.add('button-disabled')
          }

          // je selectionne le slide suivant je retire la classe d-none
          formSection.forEach((slide) => {
            if (parseFloat(slide.dataset.slideindex) === currentSlide) {
              slide.classList.remove('d-none')
              setTimeout(() => {
                slide.classList.add('slide-visible')
              }, 600)
            }
          })
        }

        function createStepList() {
          let steplength = wrapperchildren.length + 1
          for (let i = 1; i <= steplength; i++) {
            let li = document.createElement('LI')
            li.setAttribute('class', 'steper__list-item')
            li.innerHTML = i
            steplinelist.appendChild(li)
          }
        }

        //Step liner manipulation
      })
  }
}

/**
 * Ajoute un attribut html  à chaque enfants ainsi que la classe spécifié
 * @param {HTMLElement[]} element - le parent qui contient les enfants
 * @param {string} classes - la classe à ajouter au enfant
 */
function setDataAttribute(element, classes, attributes) {
  element.forEach((child, index) => {
    child.setAttribute(attributes, index + 1)
    child.classList.add(classes)
  })
}

/**
 * Modifie l'ur en fonction du slide actif
 * @param {*} index - l'index du slide actif
 */
function setSlideUrlLocation(index) {
  window.history.pushState(
    `slide-${index}`,
    'Title',
    `${getBaseUrl()}/Accueil/devweb/slide/${index}`
  )
}

/**
 * Affiche dynamiquement les input pour préciser le choix
 */
export function createDynamicInputFeild(formElementSelector) {
  //https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
  let ArrElement = Array.from(document.querySelectorAll(`${formElementSelector}`))

  if (ArrElement && ArrElement.length > 0) {
    ArrElement[0].type == 'radio'
      ? ArrElement.forEach((i) => createDynamicFieldForCheckbox(i))
      : void 0
    ArrElement[0].type == 'select-one'
      ? ArrElement.forEach((i) => createDynamicFieldForSelect(i))
      : void 0
  }
}

export function createDynamicFieldForCheckbox(el) {
  let ParentElement = el.closest('.form__block')
  let childToremove
  el.addEventListener('input', (e) => {
    if (el.value == 'oui') {
      childToremove = ParentElement.querySelector('.other__element')
      if (childToremove) return false
      insertHtmlBlock(el, el.name)
    } else {
      childToremove = ParentElement.querySelector('.other__element')
      childToremove ? ParentElement.removeChild(childToremove) : void 0
    }
  })
}

export function createDynamicFieldForSelect(el) {
  let ParentElement = el.closest('.form__block')
  let childToremove
  el.addEventListener('change', (e) => {
    let value = el.options[el.selectedIndex].value
    if (value == 'autre') {
      childToremove = ParentElement.querySelector('.other__element')
      if (childToremove) return false
      insertHtmlBlock(el, el.name)
    } else {
      childToremove = ParentElement.querySelector('.other__element')
      childToremove ? ParentElement.removeChild(childToremove) : void 0
    }
  })
}

function insertHtmlBlock(element, nameAttr) {
  let div = document.createElement('DIV')
  div.setAttribute('class', 'other__element')
  let input = document.createElement('input')
  input.setAttribute('type', 'text')
  input.setAttribute('name', `${nameAttr}`)
  input.setAttribute('class', 'form__control-input')
  input.setAttribute('placeholder', 'Préciser ici !')
  div.appendChild(input)
  let ParentElement = element.closest('.form__block')
  ParentElement.appendChild(div)
}

export function makeNextElementVisible(el) {
  let ArrElement = Array.from(document.querySelectorAll(`${el}`))
  if (ArrElement && ArrElement.length > 0) {
    ArrElement.forEach((item) => {
      let ParentElement = item.closest('.form__block')
      let elementToToggle = ParentElement.nextElementSibling

      item.addEventListener('input', (e) => {
        let element = e.target
        let elementToHide = document.querySelector(`.${element.getAttribute('elementToHide')}`)
        if (element.value == 'oui' || element.value == 'typographique') {
          elementToToggle.classList.contains('d-none')
            ? elementToToggle.classList.remove('d-none')
            : void 0
          elementToHide?.classList.add('d-none')
        } else {
          elementToToggle.classList.contains('d-none')
            ? void 0
            : elementToToggle.classList.add('d-none')
          elementToHide?.classList.remove('d-none')
        }
      })
    })
  }
}
