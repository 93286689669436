import Pages from "../../class/Pages";

export default class About extends Pages {
  constructor() {
    super({
      id: "about",
      element: ".about",
      elements: {},
    });
  }

}
