//------------- HELPERS FUNCTIONS -------------//

/**
 * Vérifie si la valeur de l'input type name est valide
 * @param {HTMLInputElement} el  input de type name à faire valider
 * @param {string} message -  La classe css de l'élément qui vas recevoir le message d'erreur | de success
 */
export function validName(el, message) {
	if (el.value.length < 2) {
		messageFormHandler(
			message,
			"success-message",
			"error-message",
			"ri-alert-line",
			"Ohh non c'est pas Conforme"
		);
	} else if (el.value.length >= 2) {
		messageFormHandler(
			message,
			"error-message",
			"success-message",
			"ri-checkbox-circle-line",
			"Way c'est Conforme"
		);
		return true;
	}
}

/**
 * Vérifie si la valeur de l'input type email est valide
 * @param {HTMLInputElement} el input de type email à faire valider
 * @param {string} message La classe css de l'élément qui vas recevoir le message d'erreur | de success
 */
export function validEmail(el, message) {
	if (validEmailForm(el.value)) {
		messageFormHandler(
			message,
			"error-message",
			"success-message",
			"ri-checkbox-circle-line",
			"Way c'est Conforme"
		);

		return true;
	} else {
		messageFormHandler(
			message,
			"success-message",
			"error-message",
			"ri-alert-line",
			"Ohh non c'est pas Conforme"
		);
	}
}

/**
 * Vérifie si un champ n'est pas vide
 * @param {string} el - le champ à verifier
 * @param {string} message - La classe css de l'élément qui vas recevoir le message d'erreur | de success
 */
export function isNotEmpty(el, message) {
	if (el !== "") {
		messageFormHandler(
			message,
			"error-message",
			"success-message",
			"ri-checkbox-circle-line",
			"Dite nous en plus"
		);
		return true;
	} else {
		messageFormHandler(
			message,
			"success-message",
			"error-message",
			"ri-alert-line",
			"Ohh non soyez moins timide"
		);
	}
}

/**
 * Gère les messages à afficher en fonction de l'input
 * @param {HTMLElement} el La classe css de l'élément qui vas recevoir le message d'erreur | de success
 * @param {string} testclass La class à tester
 * @param {string} classadded La class à ajouter
 * @param {string} icon L'icon à afficher
 * @param {string} outputtext Le message à afficger en sortie
 */
export function messageFormHandler(el, testclass, classadded, icon, outputtext) {
	if (el.style.display === "none") el.style.display = "flex";
	el.classList.contains(`${testclass}`) ? el.classList.remove(`${testclass}`) : false;
	el.classList.add(`${classadded}`);
	el.querySelector("i").className = `${icon}`;
	el.querySelector("p").textContent = `${outputtext}*`;
}

/**
 * Vérifie si un fichier est de type PDF,  DOC et DOX c'est à dire
 * un document texte
 * @param {File} file - le fichier qui doit être vérifier
 * @returns {Boolean} Boolean
 */
export function validFileType(file) {
	let fileTypes = [
		"application/pdf",
		"application/doc",
		"application/docx",
		"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		"application/msword",
	];

	for (let i = 0; i < fileTypes.length; i++) {
		if (file.type === fileTypes[i]) {
			return true;
		}
	}
	return false;
}

/**
 * Vérifie si un email est conforme
 * @param {string} email - l'email qui doit être vérifier!
 * @returns {Boolean} Boolean
 */
export function validEmailForm(email) {
	let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	if (regex.test(email)) {
		return true;
	}
}

/**
 * Exécute une fonction (un callback) lorsque l'évènement RESIZE de la fénètre ou du noeud (window or node) est terminé
 * @param {*} calback  La function qui sera appélée
 * @param {*} timeOuId une variable dans laquel stocker l'id du timerout
 */
export function onResizeEnd(calback, timeOuId) {
	clearTimeout(timeOuId);
	timeOuId = setTimeout(() => {
		calback();
	}, 500);
}

/**
 * Returne la base url du site
 * @returns {void} Une CHAINE DE CARACTÈRE représentant l'url de base du site
 */
export function getBaseUrl() {
	let getUrl = window.location;
	//let baseOld = getUrl.protocol + '//' + getUrl.host + '/' + getUrl.pathname.split('/')[1]
	let baseUrl = getUrl.protocol + "//" + getUrl.host + "/";
	return baseUrl;
}

/**
 * Redirige vers l'url spécifié après un delais définis
 * @param {string} url - l'url de redirection
 * @param {number} delay - Delais avant la redirection
 */
export function redirect(url, delay) {
	setTimeout(() => redirecTo(url), delay);
}

/**
 * Redirige vers l'url spécifier
 * @param {string} url - L'url de la page à chargé
 */
export function redirecTo(url) {
	window.location.assign(`${getBaseUrl()}/public/${url}`);
}

/**
 * Créer dynamiquement des liens pour le breadcrumbs
 * @param {HTMLElement[]} element - Le breadcrumbs à dynamiser
 */
export function dynamicBreadcrumb(element, index) {
	let component = element;

	if (component) {
		dynamicLink(component);
	}

	function dynamicLink(e) {
		let pageName = location.pathname.split("/").at(-1);
		let pagePath = location.pathname;
		if (index === 0) {
			e.append(createLink(pageName, pagePath));
			e.append(createLinkSeparator());
		} else {
			let slideName = `s-${index}`;
			e.append(createLink(slideName, pagePath));
			e.append(createLinkSeparator());
		}
	}

	function createLink(name, path) {
		let a = document.createElement("A");
		a.setAttribute("class", "breadcrumbs__link");
		a.setAttribute("href", path);
		let pathname = name[0].toUpperCase() + name.substr(1);
		a.textContent = pathname;
		//a.closest(".breadcrumbs").appendChild(createLinkSeparator());
		return a;
	}

	function createLinkSeparator() {
		let div = document.createElement("DIV");
		div.setAttribute("class", "breadcrumbs__separator");
		let i = document.createElement("I");
		i.setAttribute("class", "ri-arrow-right-s-line");
		div.append(i);
		return div;
	}
}

//https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
/**
 * Observe et execuse un callback lorsque les noeuds enfants(DOM) de la cible ont été modifier
 * @param {*} node - Le noeud DOM à observé
 * @param {*} callback - la fonction à éxécuter lorsque le DOM de la cible à changé
 */
export function ObserveNodeDomChange(node, cb, selector) {
	// Select the node that will be observed for mutations
	const targetNode = document.querySelector(`.${node}`);

	// Options for the observer (which mutations to observe)
	const config = { childList: true, subtree: true };

	//Callback function to execute when mutations are observed
	const callback = (mutationsList, observer) => {
		// Use traditional 'for loops' for IE 11
		for (const mutation of mutationsList) {
			if (mutation.type === "childList") {
				cb(selector);
			} else if (mutation.type === "attributes") {
				cb(selector);
			}
		}
	};

	// Create an observer instance linked to the callback function
	const observer = new MutationObserver(callback);

	// Start observing the target node for configured mutations
	targetNode ? observer.observe(targetNode, config) : void 0;
	//observer.observe(targetNode, config);

	// Later, you can stop observing
	//observer.disconnect();
}

/**
 * Récupère le cookie donc le nom est passé en paramère
 * @param {string} name - le nom ou la clé du cookie à récupérer
 * @returns {string} la valeur du cookie ou UNDEFINED si le cookies n'éxiste pas
 */
export function getCookie(name) {
	let matches = document.cookie.match(
		new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
	);
	return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const lerp = (start, end, amount) => {
	return (1 - amount) * start + amount * end;
};

export const clamp = (value, min, max) => {
	return Math.min(Math.max(value, min), max);
};
