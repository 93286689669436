export function createElement(name, attributes) {
	const element = Object.assign(document.createElement(name), {
		...attributes,
	});

	return element;
}

export function appendChilds(parent, childs) {
	childs.forEach((child) => {
		parent.appendChild(child);
	});
}
