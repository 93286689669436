import Pages from "../../class/Pages";
import { ObserveNodeDomChange } from "../../utils/functions";
import { dynamicBreadcrumb } from "../../utils/functions";
import {
  formFetcher,
  createDynamicInputFeild,
  makeNextElementVisible,
} from "./form.js";

export default class Form extends Pages {
  constructor() {
    super({
      id: "form",
      element: ".form",
      elements: {
        breadcrumb: ".breadcrumbs",
      },
    });
  }
  create() {
    super.create();
    this.formHandler();
    this.inputBehavior();
  }

  formHandler() {
    dynamicBreadcrumb(this.elements.breadcrumb, 0);
    formFetcher();
  }

  inputBehavior() {
    createDynamicInputFeild('input[precision="true"]');
    createDynamicInputFeild('select[precision="true"]');
    ObserveNodeDomChange(
      "form__fetch-container",
      createDynamicInputFeild,
      'input[precision="true"]'
    );
    ObserveNodeDomChange(
      "form__fetch-container",
      createDynamicInputFeild,
      'select[precision="true"]'
    );
    ObserveNodeDomChange(
      "form__fetch-container",
      makeNextElementVisible,
      'input[makeNextElementVisible="true"]'
    );
  }
}
