import Pages from "../../class/Pages";
import clickTocopy from "../../utils/copyElements";

export default class Contact extends Pages {
  constructor() {
    super({
      id: "contact",
      element: ".contact",
      elements: {
        copiedElements: ".copy",
      },
    });
  }

  create() {
    super.create()
    this.clickTocopy();
  }

  clickTocopy() {
    clickTocopy(Array.from(this.elements.copiedElements))
  }
}


// let plus = Array.from(document.querySelectorAll(".plus"))

// plus.forEach(icon => icon.onclick = (e) => {
//   this.querySelector('.revell').classList.toggle('toggle')
// }
// )

// //Dans ton css
//   .revell {
//   display: inline - block;
// }

//   .toggle {
//   display : none
// }

