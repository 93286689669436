import Pages from '../../class/Pages'
import Accordion from '../../components/Accordion'

export default class Faq extends Pages {
  constructor() {
    super({
      id: 'faq',
      element: '.faq',
      elements: {
        faqList: '.faq__list__item',
      },
    })
  }

  create() {
    super.create()
    //accordion
    this.accordion = new Accordion('.faq__list__item')
  }
}
