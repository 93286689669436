import Tooltips from "./tooltips";

export default function clickTocopy(arr) {
  let filterItems = arr.filter((node) => node.dataset.copied === "true");
  copyAlertNotification();
  filterItems.forEach((i) => i.addEventListener("click", copyElement));
  filterItems.forEach((el) => new Tooltips(el));
}

function copyAlertNotification() {
  let span = document.createElement("SPAN");
  span.setAttribute("class", "copyAlert");
  span.textContent = "Texte copier dans le presse papier";
  document.querySelector(".copyAlert") ? void 0 : document.body.append(span);
}

function copyElement(e) {
  e.preventDefault;
  let textToCopy = this.textContent;
  navigator.clipboard.writeText(textToCopy).then(success, error);
  function success() {
    let notif = document.querySelector(".copyAlert");
    notif.classList.contains("copyAlert-visible")
      ? void 0
      : notif.classList.add("copyAlert-visible");

    setTimeout(() => {
      notif.classList.remove("copyAlert-visible");
    }, 3000);
  }

  function error() {
    console.error("une érreur c'est produite lors de la copie de du text");
  }
}
