import Pages from "../../class/Pages";

export default class Page404 extends Pages {
  constructor() {
    super({
      id: "Page404",
      element: ".page404",
      elements: {},
    });
  }

  create() {
    super.create();
  }

}
