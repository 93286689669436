export default class Accordion {
  constructor(elements) {
    this.elements = [...document.querySelectorAll(elements)]
    this.init()
  }

  init() {
    this.addEventListener()
    if (this.elements && this.elements.length > 0) {
      this.elements[0].classList.add('isActive');
    }
    
  }

  onClick(e) {
    this.current = e.currentTarget
    let text = this.current.querySelector('.faq__list__items__desc')
    let icon = this.current.querySelector('.faq-dropdown')

    this.current.classList.toggle('isActive')
  }

  addEventListener() {
    this.elements.forEach((element) => {
      element.addEventListener('click', this.onClick)
    })
  }
}
