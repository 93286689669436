import { $, $All } from "./shortselector";

export default class TeamSlider {
	constructor(callback) {
		this.elements = {
			imageContainer: $(".nous__slider__images"),
			content: $(".nous__slider__content"),

			navigation: {
				buttons: {
					left: $("#slider__button-left"),
					right: $("#slider__button-right"),
				},

				preview: $(".nous__slider__preview"),
			},
		};

		//Slider image, content
		this.container = this.elements.imageContainer;
		this.images = [...this.elements.imageContainer.children];
		this.previewButtons = [...this.elements.navigation.preview.children];

		//Button left and right and preview button - slider navigation
		this.buttonLeft = this.elements.navigation.buttons.left;
		this.buttonRight = this.elements.navigation.buttons.right;
		this.contentChild = [...this.elements.content.children];

		if (
			!this.container ||
			!this.images ||
			!this.buttonLeft ||
			!this.buttonRight ||
			!this.previewButtons ||
			!this.contentChild
		) {
			console.log("l'un des éléments HTML n'est pas trouvable");
		}

		this.callback = callback;
		this.init();
		this.eventListener();
	}

	init() {
		//State
		this.activeIndex = 0;
		this.targetIndex = 0;
		this.buttonLeft.classList.add("slider__buttons-disabled");
		this.wrappImages(this.images, this.activeIndex);
		this.images = [...this.elements.imageContainer.children];
		this.contentHandler();
		this.inactiveAllPreviewButton();
		this.activeButtonPreview();
	}

	activeButtonPreview() {
		this.inactiveAllPreviewButton();
		this.previewButtons[this.activeIndex].style.filter = "grayscale(0%)";
		this.previewButtons[this.activeIndex].style.opacity = "1";
	}

	inactiveAllPreviewButton() {
		this.previewButtons.forEach((button) => {
			button.style.filter = "grayscale(100%)";
			button.style.opacity = ".4";
		});
	}

	onNext(e) {
		e.preventDefault();

		if (this.activeIndex >= this.images.length - 1) {
			this.buttonRight.classList.add("slider__buttons-disabled");
			return;
		}

		this.contentChild[this.activeIndex].style.display = "none";

		this.activeIndex++;

		if (this.activeIndex >= this.images.length - 1) {
			this.buttonRight.classList.add("slider__buttons-disabled");
		}

		if (this.activeIndex > 0 && this.buttonLeft.classList.contains("slider__buttons-disabled")) {
			this.buttonLeft.classList.remove("slider__buttons-disabled");
		}

		//Active current button preview
		this.activeButtonPreview();
		//Image transition code here
		this.translateImage(false);
		this.contentChild[this.activeIndex].style.display = "inline-block";
		this.callback(this.contentChild[this.activeIndex]);
	}

	onPrev(e) {
		e.preventDefault();

		if (this.activeIndex <= 0) return;
		this.contentChild[this.activeIndex].style.display = "none";
		this.activeIndex--;

		//Active current button preview
		this.activeButtonPreview();

		if (
			this.activeIndex <= this.images.length - 1 &&
			this.buttonRight.classList.contains("slider__buttons-disabled")
		) {
			this.buttonRight.classList.remove("slider__buttons-disabled");
		}

		if (this.activeIndex === 0) {
			this.buttonLeft.classList.add("slider__buttons-disabled");
		}

		this.translateImage(true);
		this.contentChild[this.activeIndex].style.display = "inline-block";
		this.callback(this.contentChild[this.activeIndex]);
	}

	translateImageFromPreview(index) {
		const top = this.images.length;
		const currentImage = this.images[this.activeIndex];
		const targetImage = this.images[index];

		console.log(top, currentImage, targetImage);
	}

	translateImageV(current, targets) {
		if (current === targets) return;

		const target = this.images[targets];
		const currents = this.images[current];

		console.log(this.images[current], this.images[targets]);

		target.setAttribute("data-team-slider-active", "true");
		target.style.height = "100%";
		target.style.zIndex = (targets + 1).toString();

		target.ontransitionend = () => {
			currents.setAttribute("data-team-slider-active", "false");
			currents.style.height = "0%";
			currents.style.zIndex = "2";
		};
	}

	translateImage(reverse) {
		let currentImage = this.images[this.activeIndex];
		let oldImage = this.images[this.activeIndex - 1];

		if (reverse === true) {
			oldImage = this.images[this.activeIndex];
			currentImage = this.images[this.activeIndex + 1];
			currentImage.style.height = "0%";
			return;
		}

		currentImage.setAttribute("data-team-slider-active", "true");
		currentImage.style.height = "100%";
		currentImage.style.zIndex = this.activeIndex + 1;

		currentImage.ontransitionend = () => {
			if (!oldImage) return;
			oldImage.setAttribute("data-team-slider-active", "false");
			oldImage.style.height = "0%";
		};
	}

	wrappImages(elements, activeIndex) {
		const el = elements.map((l, index) => {
			const parent = document.createElement("div");
			parent.classList = "nous__slider__images__item__container";
			if (activeIndex === index) {
				parent.setAttribute("data-team-slider-active", "true");
			}
			parent.appendChild(l);
			return parent;
		});

		//Je vide le container des images
		this.container.innerHTML = "";

		//J'ajoute les enfants entourés d'une div
		el.forEach((element) => {
			this.container.appendChild(element);
		});
	}

	contentHandler() {
		this.contentChild.forEach((el) => {
			el.style.display = "none";
		});
		this.contentChild[this.activeIndex].style.display = "inline-block";
	}

	hideContent(currentIndex) {
		this.contentChild[currentIndex - 1].style.display = "none";
		this.contentChild[currentIndex].style.display = "inline-block";
	}

	previewHandler(index) {
		this.activeIndex = index;
		this.activeButtonPreview();
		//this.translateImageV(this.targetIndex, this.activeIndex);
		this.translateImageFromPreview(index);
		this.contentHandler();
	}

	eventListener() {
		this.buttonRight.addEventListener("click", this.onNext.bind(this));
		this.buttonLeft.addEventListener("click", this.onPrev.bind(this));
		this.previewButtons.forEach((button, index) => {
			button.addEventListener("click", (e) => {
				e.preventDefault();
				////this.previewHandler(index);
			});
		});
	}
}
