import Pages from "../../class/Pages";
import { animeLogo } from "../../utils/animation/animLogo";

import HomeForm from "../forms/homeform";
import Accordion from "../../components/Accordion";

import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

export default class Home extends Pages {
	constructor() {
		super({
			id: "home",
			element: ".home",
			elements: {
				mouse: ".mouse-cusor",
				cards: ".t-cards",
				homeLogos: ".hero__tools-items",
			},
		});

		Swiper.use([Navigation, Pagination, Autoplay]);
	}

	create() {
		super.create();
		this.homeform = HomeForm();
		this.accordion = new Accordion(".faq__list__item");
		this.testimonialCarousel();
	}

	testimonialCarousel() {
		const swiper = new Swiper(".t-cards", {
			grabCusor: true,
			spaceBetween: 48,
			initialSlide: 1,

			// If we need pagination
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},

			navigation: false,

			dynamicBullets: true,

			autoplay: {
				delay: 10000,
			},

			breakpoints: {
				568: {
					slidesPerView: 2,
				},

				1024: {
					slidesPerView: 3,
				},
			},
		});
	}

	// animlogo() {
	// 	this.animOps = {
	// 		duration: 4000,
	// 		startIndex: 0,
	// 	};
	// 	animeLogo(Array.from(this.elements.homeLogos), this.animOps);
	// }
}
