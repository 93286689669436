export default {
	projets: [
		{
			id: 1,
			title: "Lafonke",
			tags: ["Ecommerce", "Développement web"],
			video: {
				alt: "",
				src: "videos/incub.mp4",
			},
			link: "https://www.lafonke.fr",
			description: "Courte description du site",
		},
		{
			id: 2,
			title: "Incub",
			tags: ["application web", "Développement web"],
			video: {
				alt: "",
				src: "videos/incub.mp4",
			},

			link: "https://incub-server.onrender.com",
			description: "Courte description du site",
		},
		{
			id: 3,
			title: "Afroeats",
			tags: ["Web design", "UI design"],
			video: {
				alt: "",
				src: "videos/incub.mp4",
			},

			link: "https://www.afroeats.fr",
			description: "Courte description du site",
		},
		{
			id: 4,
			title: "Rogerbentcha portfolio",
			tags: ["Développement web", "Web design"],
			video: {
				alt: "",
				src: "videos/incub.mp4",
			},

			link: "https://www.rogerbentcha.fr",
			description: "Courte description du site",
		},
		{
			id: 5,
			title: "JonathanFaton portfolio",
			tags: ["Développement web", "Web design"],
			video: {
				alt: "",
				src: "videos/incub.mp4",
			},

			link: "https://www.fatonjonathan.fr",
			description: "Site développer avec le framework ECHO",
		},
		
	],

	pricings: [
		{
			service: "Site vitrine",
			description: "Sur devis uniquement",
			features: [
				"Création de 6-10 Pages Impactantes",
				"Conception Web Artistique et Convaincante",
				"Intégration Dynamique des Réseaux Sociaux",
				"Formulaire de Contact Intuitif et Réactif",
				"Sécurité Avancée pour une Protection Totale",
				"Hébergement Fiable pour une Performance Optimale",
				"Optimisation SEO pour une Visibilité Maximale",
				"Gestion de Contenu Facile et Intuitive",
				"Totalement responsive pour une meilleur Expérience",
			],
			
			link: window.baseUrl + "Accueil/devweb",
		},
		{
			service: "Ecommerce / Boutique en ligne",
			description: "Sur devis uniquement",
			features: [
				"Conception Web Épurée et Captivante",
				"Options de Paiement Diversifiées",
				"Formation Utilisateur Intuitive",
				"Hébergement & Référencement Stratégiques",
				"Support Multilingue pour une Portée Globale",
				"Gestion Facile d'Inventaire avec Ajout de Produit",
				"Outils Statistiques Puissants",
				"Synchronisation avec les Réseaux Sociaux",
				"Système de Commentaires et Avis Clients",
			],			
			link: window.baseUrl + "Accueil/devweb",
		},
		{
			service: "Application web",
			description: "Sur devis uniquement",
			features: [
				"Étude Approfondie de Faisabilité",
				"Maquettage Créatif et Convaincant",
				"Développement Sur Mesure et Évolutif",
				"Sécurité de Pointe pour une Confiance Absolue",
				"Maintenance Préventive et Réactive",
				"Hébergement Optimal et Référencement Stratégique",
				"Outils Statistiques Intelligents",
				"Campagnes Publicitaires Impactantes",
			],			
			link: window.baseUrl + "Accueil/devweb",
		},
		{
			service: "Application Mobile",
			description: "Sur devis uniquement",
			features: [
				"Design Unique & Éblouissant",
				"Développement Sur Mesure et Innovant",
				"Compatibilité sur IOS et/ou Android",
				"Hébergement Fiable et Performant",
				"Maintenance Proactive et Réactive",
				"Sécurité Avancée et Confiance Renforcée",
				"Authentification Biométrique (face ID/ Touch ID)",
				"Expériences Immersives grâce aux Capteurs",
				"Connectivité Sans Limite avec le Mode Hors-ligne Étendu",
				"Synchronisation en Toute Simplicité avec le Cloud",
				"Feedback Utilisateur pour une Interaction Continue",
				"Mode Nuit pour une Utilisation Confortable",
			],
			
			link: window.baseUrl + "Accueil/devweb",
		},
	],

	tools: {
		caption: "Nos technologies et outils",
		head: [["nom", "type", "logo"]],
		rows: [
			{
				name: "Figma",
				type: "Logiciel de design",
				logo: "images/svg/vs_code.svg",
			},
			{
				name: "Photoshop",
				type: "Logiciel graphique",
				logo: "images/svg/vs_code.svg",
			},
		],
	},

	logos: [
		{
			alt: "Vs code logo",
			src: "images/svg/vs_code.svg",
		},
		{
			alt: "HTML 5 logo",
			src: "images/svg/html_5.svg",
		},
		{
			alt: "CSS3 logo",
			src: "images/svg/css_3.svg",
		},
		{
			alt: "SASS logo",
			src: "images/svg/Sass.svg",
		},
		{
			alt: "Codeigniter logo",
			src: "images/svg/Codeigniter.svg",
		},
		{
			alt: "PHP logo",
			src: "images/svg/php.svg",
		},
		{
			alt: "JAVASCRIPT logo",
			src: "images/svg/js.svg",
		},
		{
			alt: "Git logo",
			src: "images/svg/git.svg",
		},
		{
			alt: "Adobe photoshtop logo",
			src: "images/svg/AdobePhotoshop.svg",
		},
		{
			alt: "Vs code",
			src: "images/svg/vs_code.svg",
		},
		{
			alt: "Adobe Illustrator logo",
			src: "images/svg/AdobeIllustrator.svg",
		},
		{
			alt: "Figma logo",
			src: "images/svg/Figma.svg",
		},
		{
			alt: "Wordpress logo",
			src: "images/svg/wordpress.svg",
		},
		{
			alt: "Laravel logo",
			src: "images/svg/laravel.svg",
		},
		{
			alt: "React logo",
			src: "images/svg/react.svg",
		},
		{
			alt: "Typescript logo",
			src: "images/svg/typescript.svg",
		},
		{
			alt: "SQL logo",
			src: "images/svg/sql.svg",
		},
		{
			alt: "Mongo DB logo",
			src: "images/svg/mongodb.svg",
		},
		{
			alt: "Node JS logo",
			src: "images/svg/node.svg",
		},
		{
			alt: "Next Js logo",
			src: "images/svg/nextjs.svg",
		},
		
	],
};
